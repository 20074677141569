import './App.css';
import QRCode from "react-qr-code";

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const user = queryParams.get('user');
  const password = queryParams.get('password');
  const qr = queryParams.get('qr');
  if (qr) return (
    <div className="App" style={{paddingTop: 100}}>
      <QRCode value={qr} />
    </div>
  )

  let showShortPassword = false;
  if (password && password.length > 50) showShortPassword = true;

  return (
    <div className="App">
        <p>User</p>
        <p>{user || ''}</p>
        {
          showShortPassword ? <>
          <p>Short Password</p>
          <p>{password.substring(0, 50) || ''}</p>
          </> : null
        }
        <p>Password</p>
        <p>{password || ''}</p>
    </div>
  );
}

export default App;
